<template>
  <div>
    <div class="home-page">
      <div style="height: 100%">
        <el-carousel
          :interval="5000"
          arrow="always"
          :height="carouselHeight + 'px'"
          @change="changeImg"
          ref="carouselsss"
        >
        <el-carousel-item  class="imgs" @mouseenter.native="play"  v-for="(url, id) in Itemsrc0"
              :key="`${(id + 1)}`">
          <img ref="Items" style="height: 900px"  :src="url.imgUrl" @load="resize()" />
            <h2
              class="hinge3 animate__animated animate__fadeInRight"
              style="text-align: center;color:#fff;"
              v-if="visiable3"
            >
              <span style="font-size: 70px; font-weight: 600"
                >{{ url.name }}</span>
              <br />
              <br />
              <br />
              {{ url.routerName }}
            </h2>
          </el-carousel-item>
          <el-carousel-item style="height: 900px" class="imgs" @mouseenter.native="play" v-for="(url, id) in Itemsrc1" :key="`${(id + 2)}`">
            <img :src="url.imgUrl" />
            <h2
              class="hinge2 animate__animated animate__fadeInRight"
              style="text-align: center;color:#fff"
              v-if="visiable2"
            >
              <span style="font-size: 70px; font-weight: 1000">
                {{ url.name }}</span>
              <br />
              <br />
              <br />
              {{ url.routerName }}
            </h2>
          </el-carousel-item>

          <el-carousel-item class="imgs" style="height: 900px" @mouseenter.native="play" v-for="(url, id) in Itemsrc2" :key="`${(id + 3)}`">
            <img  :src="url.imgUrl" />
            <h2
              style="color: green; text-align: center;display: flex;justify-content: center;align-items: center;flex-direction: column;"
              class="hinge1 swiper-slide animate__animated animate__fadeInDown"
              v-if="visiable1"
            >
              <span style="font-size: 70px; font-weight: 1000"
                >{{ url.name }}</span
              >
              <br />
              <br />
              {{ url.routerName }}
            </h2>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- //公司简介 -->
      <div class="back-page">
        <div class="section">
          <div style="margin-top: 24px" class="synopsis">
            <i></i>
            <h2>公司简介</h2>
            <em></em>
            <p>ABOUT US</p>
          </div>
          <div class="synopsis-text">
            <h1 style="margin-bottom:20px;">
              <span>领跑，</span>
              有机垃圾处理行业
            </h1>
            <p>
              <span style="color:green;font-size:25px;font-weight:bold;">苏州腾康环保科技有限公司</span>提供“有机废弃物”无害化、减量化、资源化一站式解决方案及生态农业循环经济产业园的推广建设
            </p>
            <p>
              公司团队在“有机废弃物”领域的技术研究和工程实践多年，参与了“十一五”“十二五”等科技支撑项目的研究和示范项目的实施
                  专利技术<span style="color:coral;font-size: 20px;font-weight: bold;">25+</span>
            </p>
            <p>
              自主研发的餐厨废弃物、厨余废弃物等有机废弃物无害化处理与资源化利用成套设备系列<span style="color:coral;font-size: 20px;font-weight: bold;">20+</span>
            </p>
            <p>餐厨、厨余等有机废弃物处理项目的设计、安装、收运及生产运行管理的成功经验<span style="color:coral;font-size: 20px;font-weight: bold;">25+</span> </p>
          </div>
          <div class="synopsis-img">
            <div class="tp-video">
              <video
                width="640"
                height="368"
                style="margin: auto"
                autoplay
                muted
                loop
                src="../../assets/images/synopsis/tkfull.mp4"
              >
                您的浏览器不支持 video 标签。
              </video>
            </div>
          </div>
          <div class="synopsis-more">
            <router-link to="companyprofile">MORE +</router-link>
          </div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="plan-page">
        <div class="plan-content">
          <div class="plan-title">
            <i class="b-b"></i>
            <h2 class="w-b">解决方案</h2>
            <em></em>
            <p class="plan-wb">SOLUTION</p>
          </div>
        </div>
        <div class="plan-contentBox">
          <div class="card-container" style="margin:auto">
            <div class="card-title">
              <div
                style="
                  font-size: 25px;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 10px;
                  color: #fff;
                "
              >
                综合处理方案
              </div>
              <span>
                适用于日处理量在50t~500t的餐厨、厨余、果蔬等有机垃圾的处理。所建设的项目设备科学先进，工艺路线完整，配套设施齐全，资源利用程度高。
              </span>
              <div class="plan-img">
                <el-carousel
                  indicator-position="outside"
                  arrow="never"
                  :interval="2500"
                  ref="carousels"
                >
                  <el-carousel-item @mouseenter.native="play" v-for="(item,id) in imgList1" :key="id">
                    <img :src="item.imgUrl" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="card-title">
              <div
                style="
                  font-size: 25px;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 10px;
                  color: #fff;
                "
              >
                模块化餐厨,厨余处理方案
              </div>
              <span>
                适用于日处理量在5t-100t的餐厨、厨余、果蔬等有机垃圾的处理。处置设备占地面积小，运行稳定性强、自动化程度高，可远程监管和操控。模块功能可按需选配。
              </span>
              <div class="plan-img">
                <el-carousel
                  indicator-position="outside"
                  arrow="never"
                  :interval="2500"
                  ref="carouselss"
                >
                <el-carousel-item @mouseenter.native="play" v-for="(item,id) in imgList2" :key="id">
                    <img :src="item.imgUrl" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="card-title">
              <div
                style="
                  font-size: 25px;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 10px;
                  color: #fff;
                "
              >
                生态农业综合利用方案
              </div>
              <span>
                利用先进资源化技术与有机废弃物处置过程中所产生的有机物相结合，做到产生物的无害化、资源化的全循环处置，结合现代生态农业，提高农业产出与价值。
              </span>
              <div class="plan-img">
                <el-carousel
                  indicator-position="outside"
                  arrow="never"
                  :interval="2500"
                  ref="carousel"

                >
                <el-carousel-item @mouseenter.native="play" v-for="(item,id) in imgList3" :key="id">
                    <img :src="item.imgUrl" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
        <div class="plan-more">
          <router-link to="solution">查看更多</router-link>
        </div>
      </div>

      <!-- 模块设备 -->
      <div class="device-page">
        <div class="device-content">
          <div class="synopsis">
            <i></i>
            <h2>模块化技术</h2>
          </div>
          <div class="device-box">
            <ul v-for="(item,id) in Itemsrc00" :key="`${(id + 4)}`">
              <li>
                  <div @click="goTo(item.id)">
                  <div class="img-boxs">
                    <img
                      :data-src="item.imgUrl"
                      src=""
                      :title="item.name"
                      class="img-slace"
                      :alt="item.name"
                    />
                    <div class="device-text">
                      <h4>{{ item.name }}</h4>
                      <span>了解详情</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            &nbsp;
            <br />
            <br />
            <div class="device-more">
              <router-link to="modularEquipment">查看更多</router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- 工程案例 -->
      <div class="case-page">
        <div class="case-content">
          <div class="plan-title">
            <i class="b-b"></i>
            <h2 class="w-b">工程案例</h2>
            <em></em>
            <p class="plan-wb">CASE</p>
          </div>
          <div class="case-box">
            <div class="case-box">
              <ul>
                <li>
                  <div>
                    <div class="img-box">
                      <el-carousel
                        indicator-position="outside"
                        arrow="never"
                        type="card"
                        :interval="2000"
                        style="height: 450px"
                      >
                      <el-carousel-item @mouseenter.native="play" style="height:450px;" v-for="(item,id) in imgList4" :key="id">
                    <img :src="item.imgUrl" @click="Aaddskip(item.id)" />
                    <div
                            style="
                              color: #fff;
                              text-align: center;
                              font-size: 20px;
                              position: absolute;
                              width: 100%;
                              height: 50px;
                              bottom:-5px;
                              padding-top: 10px;
                              background-color: #87ceeb;
                            "
                          >
                            {{ item.name }}
                          </div>
                  </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </li>
              </ul>
              &nbsp;
              <div class="case-more">
                <router-link to="projectcase">查看更多</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 新闻中心 -->
      <div class="news-page">
        <div class="news-content">
          <div class="synopsis">
            <i></i>
            <h2>新闻中心</h2>
            <em></em>
            <p>NEWS</p>
          </div>
          <div class="news-list">
            <ul>
                              <li style="background-color: #8cc6ee">
                                <div @click="addskip03">
                                  <div class="news-time">
                                    <i>19</i>
                                    <span>2024-02</span>
                                  </div>
                                  <div class="news-text">
                                    <h2> 年度上新！苏州人才政策申报月历（2024版）来啦→</h2>
                                    <p></p>
                                    <span></span>
                                  </div>
                                </div>
                              </li>

              <li style="background-color: #8cc6ee">
                <div @click="addskip01">
                  <div class="news-time">
                    <i>10</i>
                    <span>2023-12</span>
                  </div>
                  <div class="news-text">
                    <h2>
                        苏州腾康签约中国-沙特投资大会
                    </h2>
                    <p></p>
                    <span></span>
                  </div>
                </div>
              </li>
              <li style="background-color: #8cc6ee">
                <div @click="addskip02">
                  <div class="news-time">
                    <i>25</i>
                    <span>2023-04</span>
                  </div>
                  <div class="news-text">
                    <h2>
                        苏州腾康环保科技有限公司作为有机垃圾处理行业的领跑者，应邀参加第24届中国环博会
                    </h2>
                    <p></p>
                    <span></span>
                  </div>
                </div>
              </li>

            </ul>
            &nbsp;
            <br />
            <br />
            <div class="device-more">
              <router-link to="presscenter">查看更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      visiable1: false,
      visiable2: false,
      visiable3: true,
      imgList4: [
      {
        id: 107,
          name: "广安市餐厨废弃物处理项目",
          imgUrl: require("@/assets/images/project/1648887036.png"),
        },
        {
          id: 110,
          name: "徐州丰县餐厨垃圾项目",
          imgUrl: require("@/assets/images/project/6-1 (1).png"),
        },
        {
          id: 12,
          imgUrl: require("@/assets/images/project/1648888333.png"),
          name: "射洪市餐厨垃圾处理项目",
        },
        {
          id: 166,
          imgUrl: require("@/assets/images/project/bozhou666.webp"),
          name: "亳州市餐厨废弃物处理项目",
        },
        {
          id: 2,
          imgUrl: require("@/assets/images/home/大名1.webp"),
          name: "邯郸市大名县餐厨垃圾 PPP 项目",
        },
        {
          id: 3,
          imgUrl: require("@/assets/images/project/120-1.png"),
          name: "昆山餐厨垃圾项目(改造)",
        },
        {
          id: 4,
          imgUrl: require("@/assets/images/home/zh1.webp"),
          name: "武穴餐厨垃圾项目",
        },
        {
          id: 5,
          imgUrl: require("@/assets/images/home/达州.webp"),
          name: "达州餐厨垃圾项目",
        },
      ],
      imgList3: [
        {
          id: 1,
          imgUrl: require("@/assets/images/project/xishan-2.webp"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/images/project/xishan-4.webp"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/images/home/鱼类.webp"),
        },
      ],
      imgList2: [
        {
          id: 1,
          imgUrl: require("@/assets/images/solution/图片2.png"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/images/home/图片40.webp"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/images/home/厨余.webp"),
        },
      ],
      imgList1: [
        {
          id: 1,
          imgUrl: require("@/assets/images/home/zh1.webp"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/images/project/6-1 (1).png"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/images/home/zh.webp"),
        },
      ],
      Itemsrc00: [
        {
          id: 1,
          imgUrl: require("@/assets/images/home/图片40.webp"),
          name: "预处理整体模块",
        },
        {
          id: 2,
          imgUrl: require("@/assets/images/home/图片41.webp"),
          name: "挤压模块",
        },
        {
          id: 3,
          imgUrl: require("@/assets/images/home/图片42.webp"),
          name: "脱水模块",
        },
      ],
      Itemsrc0: [
        {
          id: 1,
          imgUrl: require("@/assets/images/home/15.webp"),
          name: "有机垃圾处理",
          routerName: "无害化·减量化·资源化一站式解决方案",
        },
      ],
      // imageHeight: 710,
      carouselHeight: 900,
        Itemsrc1: [
        {
          id: 3,
          imgUrl: require("@/assets/images/home/16.webp"),
          name: "高新技术企业",
          routerName: "专注于餐厨垃圾,厨余垃圾,果蔬垃圾等有机垃圾处理领域",
        },
      ],
      Itemsrc2: [
        {
          id: 4,
          imgUrl: require("@/assets/images/solution/图片02.webp"),
          name: "生态农业综合处理利用",
          routerName: "专注于现代农业与有机废物资源化综合利用领域",
        },
      ]
    };
  },
  mounted() {
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.resize()
    },

  window.addEventListener('scroll', this.getHeight) // 添加对于滚动事件的监听
    this.getMsg(this.index)
  },
  destroyed() {
    localStorage.clear()// 清除本地存储里面的数据
    window.removeEventListener('scroll', this.getHeight) // 移除对于滚动事件的监听
  },



  async mounted() {
    window.addEventListener("scroll", (e) => {
      // 这里做一个 节流 操作
      if (this.timer) return;
      this.timer = setTimeout(() => {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
        clearTimeout(this.timer);
        // 这里一定要把定时器置为 null
        this.timer = null
      }, 500);
    });
},
  methods: {
    getMsg(msg) {
      this.index = msg
      document.documentElement.scrollTop = localStorage.getItem('scrollTop') // 让页面滚动到点击之前菜单项的位置
    },
    getHeight() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
      localStorage.setItem('scrollTop', this.scrollTop) // 本地存储一下当前页面滚动的位置
    },
    query(selector) {
     return Array.from(document.querySelectorAll(selector));
  },
  goTo(e){
    console.log(e);
    if(e=='1'){
      this.$router.push({
        path: "./modularEquipment",
        query: {
          id: 1,
        },
      });
    }else if(e=='2'){
      this.$router.push({
        path: "./modularEquipment",
        query: {
          id: 2,
        },
      });
    }else if(e=='3'){
      this.$router.push({
        path: "./modularEquipment",
        query: {
          id: 3,
        },
      });
    }

  },
    resize() {
      // 监听屏幕视口大小如果'resize',发生改变了，就获取图片的高度height，然后渲染到页面
      this.$nextTick(() => {
        this.carouselHeight = this.$refs.Items[0].height
      })
    },
    Aaddskip(e){
      if(e=='12'){
        this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 2,
        },
      });
    }else if(e=='166'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 5,
        },
      });
    }else if(e=='2'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 101,
        },
      });
    }else if(e=='3'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 102,
        },
      });
    }else if(e=='4'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 103,
        },
      });
    }
    else if(e=='107'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 3,
        },
      });
    }   else if(e=='110'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 6,
        },
      });
    }   else if(e=='5'){
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 104,
        },
      });
    }


    },
    addskip01() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 1001,
        },
      });
    },
    addskip02() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 0,
        },
      });
    },
    addskip03() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 1002,
        },
      });
    },
    add() {

    },
    play() {
      this.$refs.carousel.handleMouseEnter = this.add
      this.$refs.carousels.handleMouseEnter = this.add
      this.$refs.carouselss.handleMouseEnter = this.add
      this.$refs.carouselsss.handleMouseEnter = this.add
    },
    changeImg(e) {
      if (e == 0) {
        this.visiable3 = true;
        this.visiable2 = false;
        this.visiable1 = false;
      } else if (e == 1) {
        this.visiable2 = true;
        this.visiable1 = false;
        this.visiable3 = false;
      } else {
        this.visiable1 = true;
        this.visiable2 = false;
        this.visiable3 = false;
      }
    },
    addskip1() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 100,
        },
      });
    },
  },
};
</script>
<style scoped>
.hinge1 {
  font-size: 30px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  padding: 30px;
  letter-spacing: 11px;
  width: 70%;
  top: 35%;
  left: 15%;
  min-width: none;
  vertical-align: middle;
}
.hinge2 {
  font-size: 30px;
  font-weight: 400;
  width:60%;
  color: #000;
  position: absolute;
  letter-spacing: 11px;
  padding: 30px;
  top: 35%;
  left: 20%;
}
.hinge3 {
  font-size: 30px;
  font-weight: 400;
  width: 70%;
  color:#2376b7;
  position: absolute;
  letter-spacing: 11px;
  padding: 30px;
  top: 35%;
  left: 15%;
}

.fadeActive {
  transition: opacity 0.5s;
}
.fadeEnter {
  opacity: 0;
}

/deep/.el-carousel__container {
  position: relative;
  height: 300px;
}
/deep/.card-title span {
  padding: 10px 0;
  line-height: 25px;
  padding-left: 32px;
  text-indent: 48px !important;
  text-align: left !important;
  color: #fff;
}
.card-title {
  height: 500px;
  width: 400px;
  /* min-width: 30%; */
  margin: 1%;
  float: left;
}
/deep/.el-carousel__arrow {
  top: 350px;
}
.tp-video {
  margin: 50px 0px 20px 300px;
}
.home-page {
  overflow: hidden;
  min-width: 1250px;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}
.card-container {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.case-box ul li {
  background-color: #8cc6ee;
}
.img-box img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.img-boxs img {
  width: 370px;
  height: 300px;
  overflow: hidden;
  position:relative;
}
.img-slace {
  transition: all 0.5s ease 0.1s;
}
.img-slace:hover {
  transform: scale(1.05);
}
.synopsis-more a:hover {
  background: #8cc6ee;
  color: #fff;
}
.case-more a:hover {
  background: #fff;
  color: #8cc6ee;
}
.device-more a:hover {
  background: #8cc6ee;
  color: #fff;
}
.plan-more a:hover {
  background: #fff;
  color: #8cc6ee;
}
.news-list {
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
}
.news-list .news-time {
  width: 100%;
  overflow: hidden;
}
.news-list .news-time i {
  display: block;
  float: left;
  font-style: normal;
  width: 60px;
  line-height: 60px;
  font-size: 29px;
  background: #2376b7;
  color: #fff;
  text-align: center;
}
.news-list .news-time span {
  display: block;
  float: left;
  font-size: 28px;
  width: 135px;
  height: 20px;
  line-height: 15px;
  margin-top: 40px;
  color: #fff;
  text-align: center;
}
.news-text {
  width: 90%;
  margin: 20px auto;
}
.news-text h2 {
  font-size: 16px;
  color: #fff;
  height: 28px;
  line-height: 28px;
}
.news-text p {
  font-size: 13px;
  overflow: hidden;
  color: #fff;
  height: 104px;
  margin: 10px 0;
  line-height: 26px;
}
.news-list ul li {
  width: 31.333%;
  margin: 10px 1%;
  float: left;
  overflow: hidden;
}
.news-text span {
  display: block;
  width: 115px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
}
.news-text span:hover {
  color: #fff;
  background-color: #8cc6ee;
}
.news-page {
  width: 100%;
  padding: 30px 0;
  overflow: hidden;
  background: #fff;
}
.news-content {
  width: 1200px;
  margin: auto;
}
.case-text h4 {
  color: #555555;
  width: 100%;
  margin: auto;
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
}
.case-text p {
  line-height: 23px;
  height: 23px;
  overflow: hidden;
}
.case-page {
  width: 100%;
  overflow: hidden;
  background-color: #8cc6ee;
  padding: 20px 0;
}
.case-content {
  width: 1400px;
  height: 730px;
  margin: auto;
}
.case-box {
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
}
.case-box ul li {
  width: 100%;
  float: left;
  overflow: hidden;
}
.device-box ul li {
  width: 31.333%;
  margin: 5px 1%;
  float: left;
  overflow: hidden;
}
.device-box .device-text {
  width: 90%;
  margin: auto;
  padding: 10px 0 15px 0;
}
.device-box .device-text h4 {
  font-size: 23px;
  line-height: 40px;
  color: #2d2d2d;
}
.device-box .device-text p {
  font-size: 14px;
  color: #636363;
  line-height: 23px;
  height: 46px;
  overflow: hidden;
  margin-bottom: 10px;
  text-indent: 2em;
  text-align: left;
}
.device-box .device-text span {
  display: block;
  width: 140px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #999999;
  color: #999999;
  text-align: center;
}
.device-box .device-text span {
  text-align: center;
  color: #504f4f;
  border-radius: 20px;
  margin: auto;
}
.device-box .device-text span:hover {
  background: #8cc6ee;
  border: 1px solid #8cc6ee;
  color: #fff;
}
.case-more {
  width: 100%;
  padding: 10px 0;
  margin-top: 25px;
}
.case-more a {
  display: block;
  border: 2px solid #fff;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: auto;
  width: 190px;
  height: 40px;
  text-transform: uppercase;
  border-radius: 30px;
}
.device-more {
  width: 100%;
  padding: 40px 0;
}
.device-more a {
  display: block;
  border: 2px solid #8cc6ee;
  line-height: 40px;
  color: #8cc6ee;
  font-size: 14px;
  text-align: center;
  margin: auto;
  width: 190px;
  height: 40px;
  text-transform: uppercase;
  border-radius: 30px;
}
.plan-more {
  width: 100%;
  padding: 0px 0;
  margin:20px auto;
}
.plan-more a {
  display: block;
  border: 2px solid #fff;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: auto;
  width: 190px;
  height: 40px;
  text-transform: uppercase;
  border-radius: 30px;
}
.device-page {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.device-content {
  width: 1450px;
  margin: auto;
  padding: 20px 0;
  text-align: center;
}
.plan-contentBox {
  width: 100%;
  height: 500px;
  position: relative;
  /* margin-top: 30px; */
  margin:20px auto;
  /* margin: auto; */
}
ul {
  list-style: none;
}
.plan-title .b-b {
  background: #fff;
  display: block;
  width: 2px;
  height: 32px;
  margin: auto;
}
.plan-title .plan-wb {
  color: #fff;
}
.plan-title p {
  font-size: 16px;
  padding: 5px 0;
  text-transform: uppercase;
}
.plan-title .w-b {
  font-size: 31px;
  color: #fff;
  padding: 5px 0;
}
.plan-title em {
  display: block;
  width: 37px;
  height: 4px;
  margin: auto;
  background: #8cc6ee;
}

.plan-title {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.plan-page {
  width: 100%;
  padding: 20px 0;
  margin: auto;
  overflow: hidden;
  background-color: #8cc6ee;
}
.plan-content {
  width: 1200px;
  margin: auto;
}
.synopsis-more {
  width: 100%;
  padding: 40px 0;
}
.synopsis-more a {
  display: block;
  border: 2px solid #8cc6ee;
  line-height: 50px;
  color: #8cc6ee;
  font-size: 14px;
  text-align: center;
  margin: auto;
  width: 190px;
  height: 50px;
  text-transform: uppercase;
  border-radius: 30px;
}
.synopsis-img {
  width: 100%;
}
.synopsis-img img {
  max-width: 100%;
  display: block;
  border: 0;
}
.synopsis-text {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.synopsis-text h1 {
  font-size: 21px;
  color: #000;
  padding: 5px 0;
}
.synopsis-text h1 span {
  font-weight: bold;
  font-size: 26px;
  color: #3fa4e7;
}
.synopsis-text p {
  line-height:60px;
  font-size: 20px;
  text-align: center;
}
.synopsis-text p  span{
 color:orange;
 font-size: 30px;
 font-weight: bold;
}
.synopsis-text p img {
  height: 80px;
  width: 80px;
}
.plan-img {
  margin-top: 20px;
}
.plan-img img {
  width: 400px;
  height: 100%;
  margin: auto;
  text-align: center;
  background-size: 100% 100%;
  background: #fff;
}
.section {
  width: 1200px;
  overflow: hidden;
  padding: 30px 0;
  margin: auto;
}
.back-page {
  background: #fff;
}
.synopsis {
  text-align: center;
  width: 100%;
}
.synopsis i {
  display: block;
  width: 2px;
  height: 32px;
  margin: auto;
  background: #b5b5b5;
}
.synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 5px 0;
}
.synopsis em {
  display: block;
  width: 37px;
  height: 4px;
  margin: auto;
  background: #8cc6ee;
}
.synopsis p {
  color: #6c6c6c;
  font-size: 14px;
  padding: 5px 0;
  text-transform: uppercase;
}

.imgs img {
  height: 900px;
  width: 100%;
  border: none;
}
</style>
